import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './testimonial17.css'

const Testimonial17 = (props) => {
  return (
    <div className="thq-section-padding">
      <div className="testimonial17-max-width thq-section-max-width">
        <div className="testimonial17-container10">
          <h2>
            {props.heading1 ?? (
              <Fragment>
                <h2 className="testimonial17-text35 thq-heading-2">
                  Life-changing Experience with Dr. Abdul Sathar Athanikkal
                </h2>
              </Fragment>
            )}
          </h2>
          <span>
            {props.content1 ?? (
              <Fragment>
                <span className="testimonial17-text32 thq-body-small">
                Dr. Abdul Sathar Athanikkal is a highly skilled psychiatrist who has helped many people overcome anxiety disorders. His compassionate approach and effective treatment methods have made a significant difference in many lives.
                </span>
              </Fragment>
            )}
          </span>
        </div>
        {/* <div className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card1"
              >
                <div className="testimonial17-container12">
                  <img
                    alt={props.author1Alt}
                    src={props.author1Src}
                    className="testimonial17-image1"
                  />
                  <div className="testimonial17-container13">
                    <strong>
                      {props.author1Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text26 thq-body-large">
                            John Doe
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author1Position ?? (
                        <Fragment>
                          <span className="testimonial17-text25 thq-body-small">
                            Patient
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review1 ?? (
                    <Fragment>
                      <span className="testimonial17-text34 thq-body-small">
                        I am truly grateful for Dr. Athanikkal&apos;s expertise
                        and support throughout my treatment journey.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card2"
              >
                <div className="testimonial17-container14">
                  <img
                    alt={props.author2Alt}
                    src={props.author2Src}
                    className="testimonial17-image2"
                  />
                  <div className="testimonial17-container15">
                    <strong>
                      {props.author2Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text29 thq-body-large">
                            Jane Smith
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author2Position ?? (
                        <Fragment>
                          <span className="testimonial17-text24 thq-body-small">
                            Patient&apos;s Family Member
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review2 ?? (
                    <Fragment>
                      <span className="testimonial17-text28 thq-body-small">
                        Dr. Athanikkal&apos;s dedication to his patients&apos;
                        well-being is truly commendable. He not only treats the
                        illness but also provides emotional support to the
                        families.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card3"
              >
                <div className="testimonial17-container16">
                  <img
                    alt={props.author3Alt}
                    src={props.author3Src}
                    className="testimonial17-image3"
                  />
                  <div className="testimonial17-container17">
                    <strong>
                      {props.author3Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text27 thq-body-large">
                            David Johnson
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author3Position ?? (
                        <Fragment>
                          <span className="testimonial17-text33 thq-body-small">
                            Colleague
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review3 ?? (
                    <Fragment>
                      <span className="testimonial17-text36 thq-body-small">
                        As a fellow psychiatrist, I have witnessed Dr.
                        Athanikkal&apos;s exceptional skills in diagnosing and
                        treating various mental health disorders. He is a true
                        asset to the field.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div
                data-animated="true"
                className="thq-card testimonial17-card4"
              >
                <div className="testimonial17-container18">
                  <img
                    alt={props.author4Alt}
                    src={props.author4Src}
                    className="testimonial17-image4"
                  />
                  <div className="testimonial17-container19">
                    <strong>
                      {props.author4Name ?? (
                        <Fragment>
                          <strong className="testimonial17-text31 thq-body-large">
                            Sarah Williams
                          </strong>
                        </Fragment>
                      )}
                    </strong>
                    <span>
                      {props.author4Position ?? (
                        <Fragment>
                          <span className="testimonial17-text30 thq-body-small">
                            Community Member
                          </span>
                        </Fragment>
                      )}
                    </span>
                  </div>
                </div>
                <span>
                  {props.review4 ?? (
                    <Fragment>
                      <span className="testimonial17-text37 thq-body-small">
                        I highly recommend Dr. Athanikkal to anyone seeking
                        professional help for mental health issues. His
                        expertise and caring nature make him stand out among
                        other psychiatrists.
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  )
}

// Testimonial17.defaultProps = {
//   author2Position: undefined,
//   author1Position: undefined,
//   author3Alt: "David Johnson's photo",
//   author1Name: undefined,
//   author1Src:
//     'https://images.unsplash.com/photo-1460723237483-7a6dc9d0b212?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNTg3MDEzMHw&ixlib=rb-4.0.3&q=80&w=1080',
//   author3Name: undefined,
//   review2: undefined,
//   author2Name: undefined,
//   author4Position: undefined,
//   author4Name: undefined,
//   author4Src:
//     'https://images.unsplash.com/photo-1492184880394-ca1608178b6b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNTg3MDEzMXw&ixlib=rb-4.0.3&q=80&w=1080',
//   author1Alt: "John Doe's photo",
//   author2Src:
//     'https://images.unsplash.com/photo-1574184739165-f7614d23f4d1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNTg3MDEzMXw&ixlib=rb-4.0.3&q=80&w=1080',
//   author3Src:
//     'https://images.unsplash.com/photo-1482555670981-4de159d8553b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyNTg3MDEzMXw&ixlib=rb-4.0.3&q=80&w=1080',
//   author2Alt: "Jane Smith's photo",
//   author4Alt: "Sarah Williams' photo",
//   content1: undefined,
//   author3Position: undefined,
//   review1: undefined,
//   heading1: undefined,
//   review3: undefined,
//   review4: undefined,
// }

// Testimonial17.propTypes = {
//   author2Position: PropTypes.element,
//   author1Position: PropTypes.element,
//   author3Alt: PropTypes.string,
//   author1Name: PropTypes.element,
//   author1Src: PropTypes.string,
//   author3Name: PropTypes.element,
//   review2: PropTypes.element,
//   author2Name: PropTypes.element,
//   author4Position: PropTypes.element,
//   author4Name: PropTypes.element,
//   author4Src: PropTypes.string,
//   author1Alt: PropTypes.string,
//   author2Src: PropTypes.string,
//   author3Src: PropTypes.string,
//   author2Alt: PropTypes.string,
//   author4Alt: PropTypes.string,
//   content1: PropTypes.element,
//   author3Position: PropTypes.element,
//   review1: PropTypes.element,
//   heading1: PropTypes.element,
//   review3: PropTypes.element,
//   review4: PropTypes.element,
// }

export default Testimonial17
