import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'
import wtsapp from './wtsapp1-removebg-preview.png'
import Navbar8 from '../components/navbar8'
import Hero17 from '../components/hero17'
// import Features24 from '../components/features24'
import CTA26 from '../components/cta26'
import Features25 from '../components/features25'
// import Pricing14 from '../components/pricing14'
import Steps2 from '../components/steps2'
import Testimonial17 from '../components/testimonial17'
import Contact10 from '../components/contact10'
import Footer4 from '../components/footer4'
import './home.css'

const Home = (props) => {
	return (
		<div className="home-container">
			<Helmet>
				<title>Dr Abdul Sathar Athanikkal Psychiatrist</title>
				<meta property="og:title" content="Abdul Sathar Athanikkal, Psychiatrist, Schizophrenia, Depression, Anxiety
            Disorders, Psycho sexual problems, Old age mental problems
            Sleep disorders Addictions, Alcoholism." />

			</Helmet>
			<Navbar8
				// page4Description={
				//   <Fragment>
				//     <span className="home-text100 thq-body-small">
				//       Page Four Description
				//     </span>
				//   </Fragment>
				// }
				// action1={
				//   <Fragment>
				//     <span className="home-text101">Main Action</span>
				//   </Fragment>
				// }
				// link2={
				//   <Fragment>
				//     <span className="home-text102 thq-link thq-body-small">#about</span>
				//   </Fragment>
				// }
				page1={
					<Fragment>
						<span className="home-text103 thq-body-large">Home</span>
					</Fragment>
				}
				link1={
					<Fragment>
						<span className="home-text104 thq-link thq-body-small">Home</span>
					</Fragment>
				}
			// page4={
			//   <Fragment>
			//     <span className="home-text105 thq-body-large">Contact</span>
			//   </Fragment>
			// }
			// page2={
			//   <Fragment>
			//     <span className="home-text106 thq-body-large">About</span>
			//   </Fragment>
			// }
			// link4={
			//   <Fragment>
			//     <span className="home-text107 thq-link thq-body-small">
			//       #contact
			//     </span>
			//   </Fragment>
			// }
			// page1Description={
			//   <Fragment>
			//     <span className="home-text108 thq-body-small">
			//       Page One Description
			//     </span>
			//   </Fragment>
			// }
			// page2Description={
			//   <Fragment>
			//     <span className="home-text109 thq-body-small">
			//       Page Two Description
			//     </span>
			//   </Fragment>
			// }
			// link3={
			//   <Fragment>
			//     <span className="home-text110 thq-link thq-body-small">
			//       #services
			//     </span>
			//   </Fragment>
			// }
			// page3={
			//   <Fragment>
			//     <span className="home-text111 thq-body-large">Services</span>
			//   </Fragment>
			// }
			// page3Description={
			//   <Fragment>
			//     <span className="home-text112 thq-body-small">
			//       Page Three Description
			//     </span>
			//   </Fragment>
			// }
			// action2={
			//   <Fragment>
			//     <span className="home-text113">Secondary Action</span>
			//   </Fragment>
			// }
			></Navbar8>
			<Hero17
				action2={
					<Fragment>
						<span className="home-text114 thq-body-small">Learn More</span>
					</Fragment>
				}
				action1={
					<Fragment>
						<h2 className="home-text115 thq-body-small">
							Book an Appointment
						</h2>
					</Fragment>
				}
				heading1={
					<Fragment>
						<h1 className="home-text116 thq-heading-1">
							Dr. Abdul Sathar Athanikkal
						</h1>
					</Fragment>
				}
				content1={
					<Fragment>
						<h3 className="home-text117 thq-body-large">
							Practicing Psychiatrist since 1990, specializing in treating a
							wide range of Mental disorders.
						</h3>
					</Fragment>
				}
			></Hero17>
			{/* <Features24
        feature3Description={
          <Fragment>
            <span className="home-text118 thq-body-small">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat.
            </span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="home-text119 thq-heading-2">Feature #3</span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="home-text120 thq-body-small">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat.
            </span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="home-text121 thq-heading-2">Feature #1</span>
          </Fragment>
        }
        feature1Description={
          <Fragment>
            <span className="home-text122 thq-body-small">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse varius enim in eros elementum tristique. Duis cursus,
              mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam
              libero vitae erat.
            </span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="home-text123 thq-heading-2">Feature #2</span>
          </Fragment>
        }
      ></Features24> */}
			<CTA26
				heading1={
					<Fragment>
						<span className="home-text124 thq-heading-2">
							Book an Appointment Today
						</span>
					</Fragment>
				}
				content1={
					<Fragment>
						<span className="home-text125 thq-body-large">
							Don&apos;t wait, take the first step towards better Mental health.
						</span>
					</Fragment>
				}
				action1={
					<Fragment>
						<span className="home-text126">
							{/* Call / WA: 9048337334
							</span> */}
							{/* <span className="home-text222"> */}
							{/* <img
								src={wtsapp} // Replace with the path to your WhatsApp icon image
								alt="WhatsApp"
								className="whatsapp-icon"
								style={{ width: "25px", height: "25px" }} // Customize icon size
							/> */}
							<a href="tel:9048337334" alt="Contact Dr. Abdula Sathara Athanikkal via WhatsApp" className="call-link">Call / WA: 9048337334</a>
							{/* <a
								href="https://wa.me/9048337334"
								target="_blank"
								rel="noopener noreferrer"
								className="whatsapp-link"
							>
							</a> */}
						</span>
						{/* <span className="whatsapp-container" style={{ marginLeft: "10px" }}>


						</span> */}
					</Fragment>
				}
			></CTA26>
			<Features25
				feature3Description={
					<Fragment>
						<span className="home-text127 thq-body-small">
							With over three decades of experience, Dr. Abdul Sathar
							Athanikkal is a trusted psychiatrist in the area.
						</span>
					</Fragment>
				}
				feature1Description={
					<Fragment>
						<span className="home-text128 thq-body-small">
							Dr. Abdul Sathar Athanikkal has been treating all Mental
							disorders since 1990, including schizophrenia, depression, anxiety
							disorders, psychosexual problems, old age Mental problems, sleep
							disorders, addictions, and alcoholism.

							{/* Dr. Abdul Sathar Athanikkal has been providing expert psychiatric care since 1990, specializing in the treatment of a wide range of mental health disorders.
							His extensive experience covers conditions such as schizophrenia, depression, and anxiety disorders, along with more complex issues like psychosexual problems,
							geriatric mental health concerns, sleep disorders, and various forms of addiction, including alcoholism. With a compassionate approach and evidence-based treatment
							methods, Dr. Athanikkal has helped countless individuals regain control of their lives, improving both their mental and emotional well-being. His dedication to mental
							health continues to make a lasting impact on his patients. */}
						</span>
					</Fragment>
				}
				feature2Title={
					<Fragment>
						<span className="home-text129 thq-heading-2">
							Online Consultation Available
						</span>
					</Fragment>
				}
				feature1Title={
					<Fragment>
						<span className="home-text130 thq-heading-2">
							Comprehensive Treatment
						</span>
					</Fragment>
				}
				feature2Description={
					<Fragment>
						<span className="home-text131 thq-body-small">
							{/* You can now consult with Dr. Abdul Sathar Athanikkal online for
							your convenience. */}
							For your convenience, Dr. Abdul Sathar Athanikkal now offers online consultations. This allows you to access expert psychiatric 
							care from the comfort of your home. Book your appointment today to receive personalized mental health support remotely.
						</span>
					</Fragment>
				}
				feature3Title={
					<Fragment>
						<span className="home-text132 thq-heading-2">
							Experienced Psychiatrist
						</span>
					</Fragment>
				}
			></Features25>
			{/* <Pricing14
        plan3Price={
          <Fragment>
            <span className="home-text133 thq-heading-3">
              Home visit consultation: $150
            </span>
          </Fragment>
        }
        plan3Action={
          <Fragment>
            <span className="home-text134 thq-body-small">Book Now</span>
          </Fragment>
        }
        plan11={
          <Fragment>
            <span className="home-text135 thq-body-large">Basic plan</span>
          </Fragment>
        }
        plan1Action={
          <Fragment>
            <span className="home-text136 thq-body-small">Book Now</span>
          </Fragment>
        }
        plan31={
          <Fragment>
            <span className="home-text137 thq-body-large">Enterprise plan</span>
          </Fragment>
        }
        plan3Feature41={
          <Fragment>
            <span className="home-text138 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan1Feature2={
          <Fragment>
            <span className="home-text139 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan2Feature11={
          <Fragment>
            <span className="home-text140 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan3Feature51={
          <Fragment>
            <span className="home-text141 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan2Feature41={
          <Fragment>
            <span className="home-text142 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan2Feature2={
          <Fragment>
            <span className="home-text143 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan3Feature21={
          <Fragment>
            <span className="home-text144 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan2Feature4={
          <Fragment>
            <span className="home-text145 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan2Yearly={
          <Fragment>
            <span className="home-text146 thq-body-large">or $299 yearly</span>
          </Fragment>
        }
        plan1Action1={
          <Fragment>
            <span className="home-text147 thq-body-small">Get started</span>
          </Fragment>
        }
        plan2Action={
          <Fragment>
            <span className="home-text148 thq-body-small">Book Now</span>
          </Fragment>
        }
        plan3Feature1={
          <Fragment>
            <span className="home-text149 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan2Feature3={
          <Fragment>
            <span className="home-text150 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan1Price1={
          <Fragment>
            <span className="home-text151 thq-heading-3">$200/yr</span>
          </Fragment>
        }
        plan2={
          <Fragment>
            <span className="home-text152 thq-body-large">Business plan</span>
          </Fragment>
        }
        plan2Feature21={
          <Fragment>
            <span className="home-text153 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan2Action1={
          <Fragment>
            <span className="home-text154 thq-body-small">Get started</span>
          </Fragment>
        }
        plan3Feature2={
          <Fragment>
            <span className="home-text155 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="home-text156 thq-body-small">
              Choose the perfect plan for you
            </span>
          </Fragment>
        }
        plan2Feature1={
          <Fragment>
            <span className="home-text157 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="home-text158 thq-heading-2">Pricing plan</span>
          </Fragment>
        }
        plan3Feature31={
          <Fragment>
            <span className="home-text159 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan1={
          <Fragment>
            <span className="home-text160 thq-body-large">Basic plan</span>
          </Fragment>
        }
        plan21={
          <Fragment>
            <span className="home-text161 thq-body-large">Business plan</span>
          </Fragment>
        }
        plan1Feature11={
          <Fragment>
            <span className="home-text162 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan1Feature21={
          <Fragment>
            <span className="home-text163 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan3Feature5={
          <Fragment>
            <span className="home-text164 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan2Yearly1={
          <Fragment>
            <span className="home-text165 thq-body-large">or $29 monthly</span>
          </Fragment>
        }
        plan2Price={
          <Fragment>
            <span className="home-text166 thq-heading-3">
              In-person consultation: $100
            </span>
          </Fragment>
        }
        plan3Yearly1={
          <Fragment>
            <span className="home-text167 thq-body-large">or $49 monthly</span>
          </Fragment>
        }
        plan2Feature31={
          <Fragment>
            <span className="home-text168 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan3Feature11={
          <Fragment>
            <span className="home-text169 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan1Yearly1={
          <Fragment>
            <span className="home-text170 thq-body-large">or $20 monthly</span>
          </Fragment>
        }
        plan2Price1={
          <Fragment>
            <span className="home-text171 thq-heading-3">$299/yr</span>
          </Fragment>
        }
        plan3Yearly={
          <Fragment>
            <span className="home-text172 thq-body-large">or $499 yearly</span>
          </Fragment>
        }
        plan3Feature4={
          <Fragment>
            <span className="home-text173 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan3Price1={
          <Fragment>
            <span className="home-text174 thq-heading-3">$499/yr</span>
          </Fragment>
        }
        plan1Feature31={
          <Fragment>
            <span className="home-text175 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan1Feature3={
          <Fragment>
            <span className="home-text176 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan1Yearly={
          <Fragment>
            <span className="home-text177 thq-body-large">or $200 yearly</span>
          </Fragment>
        }
        plan1Feature1={
          <Fragment>
            <span className="home-text178 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        plan3Feature3={
          <Fragment>
            <span className="home-text179 thq-body-small">
              Feature text goes here
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="home-text180 thq-body-large">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        plan3Action1={
          <Fragment>
            <span className="home-text183 thq-body-small">Get started</span>
          </Fragment>
        }
        plan1Price={
          <Fragment>
            <span className="home-text184 thq-heading-3">
              Online consultation: $50
            </span>
          </Fragment>
        }
        plan3={
          <Fragment>
            <span className="home-text185 thq-body-large">Enterprise plan</span>
          </Fragment>
        }
      ></Pricing14> */}
			<Steps2
				step1Description={
					<Fragment>
						<span className="home-text186 thq-body-small">
							Dr. Abdul Sathar Athanikkal can be reached at the following
							phone numbers: 9048337334, 9447534804, 6345005002. Visit the
							clinic near Bismillah Hospital in Koppam-Pattambi for a
							consultation.
						</span>
					</Fragment>
				}
				step3Description={
					<Fragment>
						<span className="home-text187 thq-body-small">
							Dr. Abdul Sathar Athanikkal provides treatment for a wide range
							of mental disorders including schizophrenia, depression, anxiety
							disorders, psychosexual problems, old age mental problems, sleep
							disorders, addictions, and alcoholism.
						</span>
					</Fragment>
				}
				step2Title={
					<Fragment>
						<span className="home-text188 thq-heading-2">Experience</span>
					</Fragment>
				}
				step2Description={
					<Fragment>
						<span className="home-text189 thq-body-small">
							With over 30 years of experience since 1990, Dr. Abdul Sathar
							Athanikkal is a seasoned psychiatrist specializing in treating
							various mental disorders.
						</span>
					</Fragment>
				}
				step1Title={
					<Fragment>
						<span className="home-text190 thq-heading-2">
							Contact Information
						</span>
					</Fragment>
				}
				step3Title={
					<Fragment>
						<span className="home-text191 thq-heading-2">
							Treatment Specialization
						</span>
					</Fragment>
				}
				step4Description={
					<Fragment>
						<span className="home-text192 thq-body-small">
							For convenience, online consultations are available with Dr.
							Abdul Sathar Athanikkal. Book an appointment by calling
							9048337334 or 94475334804.
						</span>
					</Fragment>
				}
				step4Title={
					<Fragment>
						<span className="home-text193 thq-heading-2">
							Online Consultation
						</span>
					</Fragment>
				}
			></Steps2>
			<Testimonial17
				author2Position={
					<Fragment>
						<span className="home-text194 thq-body-small">
							Patient&apos;s Family Member
						</span>
					</Fragment>
				}
				author1Position={
					<Fragment>
						<span className="home-text195 thq-body-small">Patient</span>
					</Fragment>
				}
				author1Name={
					<Fragment>
						<span className="home-text196 thq-body-large">John Doe</span>
					</Fragment>
				}
				author3Name={
					<Fragment>
						<span className="home-text197 thq-body-large">David Johnson</span>
					</Fragment>
				}
				review2={
					<Fragment>
						<span className="home-text198 thq-body-small">
							Dr. Athanikkal&apos;s dedication to his patients&apos; well-being
							is truly commendable. He not only treats the illness but also
							provides emotional support to the families.
						</span>
					</Fragment>
				}
				author2Name={
					<Fragment>
						<span className="home-text199 thq-body-large">Jane Smith</span>
					</Fragment>
				}
				author4Position={
					<Fragment>
						<span className="home-text200 thq-body-small">
							Community Member
						</span>
					</Fragment>
				}
				author4Name={
					<Fragment>
						<span className="home-text201 thq-body-large">Sarah Williams</span>
					</Fragment>
				}
				content1={
					<Fragment>
						<span className="home-text202 thq-body-small">
							{/* Dr. Abdul Sathar Athanikkal is a highly skilled psychiatrist who
              helped me overcome my anxiety disorder. His compassionate approach
              and effective treatment methods made a significant difference in
              my life. */}
							Dr. Abdula Sathar Athanikkal is a highly skilled psychiatrist known for his expertise in treating anxiety disorders.
							With a compassionate and patient-centered approach, he has helped countless individuals overcome their struggles.
							His effective treatment methods have made a profound and lasting impact on the mental well-being of many lives.
						</span>
					</Fragment>
				}
				author3Position={
					<Fragment>
						<span className="home-text203 thq-body-small">Colleague</span>
					</Fragment>
				}
				review1={
					<Fragment>
						<span className="home-text204 thq-body-small">
							I am truly grateful for Dr. Athanikkal&apos;s expertise and
							support throughout my treatment journey.
						</span>
					</Fragment>
				}
				heading1={
					<Fragment>
						<span className="home-text205 thq-heading-2">
							Life-changing Experience with Dr. Abdul Sathar Athanikkal
						</span>
					</Fragment>
				}
				review3={
					<Fragment>
						<span className="home-text206 thq-body-small">
							As a fellow psychiatrist, I have witnessed Dr. Athanikkal&apos;s
							exceptional skills in diagnosing and treating various mental
							health disorders. He is a true asset to the field.
						</span>
					</Fragment>
				}
				review4={
					<Fragment>
						<span className="home-text207 thq-body-small">
							I highly recommend Dr. Athanikkal to anyone seeking professional
							help for mental health issues. His expertise and caring nature
							make him stand out among other psychiatrists.
						</span>
					</Fragment>
				}
			></Testimonial17>
			<Contact10
				content1={
					<Fragment>
						<span className="home-text208 thq-body-large">
							Dr. Abdul Sathar Athanikkal
						</span>
					</Fragment>
				}
				location1Description={
					<Fragment>
						<span className="home-text209 thq-body-large">
							Near Bismillah Hospital, Koppam-Pattambi
						</span>
					</Fragment>
				}
				heading1={
					<Fragment>
						<span className="home-text210 thq-heading-2">
							Contact Information
						</span>
					</Fragment>
				}
				location2Description={
					<Fragment>
						<span className="home-text211 thq-body-large">
							Online consultation available
						</span>
					</Fragment>
				}
				location1={
					<Fragment>
						<span className="home-text212 thq-heading-3">
							Phone: 9048337334, 9447534804, 6345005002
						</span>
					</Fragment>
				}
				location2={
					<Fragment>
						<span className="home-text213 thq-heading-3">
							Phone: 9048337334, 94475334804
						</span>
					</Fragment>
				}
			></Contact10>
			<Footer4
				link5={
					<Fragment>
						<span className="home-text214 thq-body-small">
							Online Consultation
						</span>
					</Fragment>
				}
				link3={
					<Fragment>
						<span className="home-text215 thq-body-small">About</span>
					</Fragment>
				}
				link1={
					<Fragment>
						<span className="home-text216 thq-body-small">Home</span>
					</Fragment>
				}
				termsLink={
					<Fragment>
						<span className="home-text217 thq-body-small">
							Terms of Service
						</span>
					</Fragment>
				}
				link2={
					<Fragment>
						<span className="home-text218 thq-body-small">Services</span>
					</Fragment>
				}
				link4={
					<Fragment>
						<span className="home-text219 thq-body-small">Contact</span>
					</Fragment>
				}
				cookiesLink={
					<Fragment>
						<span className="home-text220 thq-body-small">Cookies Policy</span>
					</Fragment>
				}
				privacyLink={
					<Fragment>
						<span className="home-text221 thq-body-small">Privacy Policy</span>
					</Fragment>
				}
			></Footer4>
		</div>
	)
}

export default Home
